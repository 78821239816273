import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import { YMInitializer } from "react-yandex-metrika";
import "./App.scss";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import Footer from "./components/Home/Footer/Footer";
import ProductList from "./components/Product/ProductList";
import ProductPage from "./components/Product/ProductPage/ProductPage";
import SupplierPage from "./components/Product/Supplier/SupplierPage";
import PaymentSuccess from "./components/Profile/OrdersInfo/PaymentSuccess/PaymentSuccess";
import CartPage from "./components/Cart/CartPage";
import ProfilePage from "./components/Profile/ProfilePage";
import Test from "./components/Test";
import Fake from "./components/Login/Fake";
import LoadingBar from "./components/commons/LoadingBar/LoadingBar";
import { restore } from "./actions/profile";
import { setWindowWidth, setMinimalOrderSum } from "./actions/api";
import Help from "./pages/Help";
import Contacts from "./pages/Contacts/Contacts";
import HelpQA from "./pages/HelpQA";
import { debounce } from "./helpers/utils";
import { loadAuthData, loadRedirectData } from "./helpers/cookies";
import queryString from "query-string";
import ApplicationsLinksPage from "./components/commons/ApplicationsLinksPage/ApplicationsLinksPage";
import AddressModal from "./components/Cart/AddressModal/AddressModal";
import AddressListModal from "./components/Cart/AddressListModal/AddressListModal";
import UserJoinRequestDialog from "./components/Profile/UserJoinRequestDialog/UserJoinRequestDialog";
import CookiesAcceptModal from "./components/commons/CookiesAcceptModal/CookiesAcceptModal";
import AccountNotVerifiedModal from "./components/Profile/AccountNotVerifiedModal/AccountNotVerifiedModal";
import SideCart from "./components/Cart/SideCart/SideCart";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/commons/ErrorFallback/ErrorFallback";
import analytics from "./helpers/analytics";
import dachnik_favicon from "./images/dachnik_favicon.png";
import SuppliersLanding from "./pages/SuppliersLanding/SuppliersLanding";
import IsNotByuerModal from "./components/commons/IsNotByuerModal/IsNotByuerModal";

const { env } = global.config;
const errorHandler = (error, info) => analytics.logError(error, info);

const App = () => {
  const dispatch = useDispatch();
  let location = useLocation();

  const isMobile = useSelector((state) => state.api.isMobile);
  const isTablet = useSelector((state) => state.api.isTablet);
  const isDesktop = useSelector((state) => state.api.isDesktop);

  const isLogged = useSelector((state) => state.profile.loggedIn);
  const addressModalVisible = useSelector((state) => state.cart.addressModalVisible);
  const addressListModalVisible = useSelector((state) => state.cart.addressListModalVisible);
  const tokenInfo = loadAuthData()?.jwt || null;
  const isBuyer = tokenInfo && tokenInfo["http://rusagro.market/claims/companyrole"] === "buyer";

  useEffect(() => {
    async function preLoad() {
      const query = queryString.parse(location.search);
      const path = loadRedirectData() ? "/cart" : location.pathname;
      dispatch(restore(query.refresh_token, path));
      dispatch(setWindowWidth(window.innerWidth));
      // dispatch(setMinimalOrderSum());

      window.addEventListener(
        "resize",
        debounce(() => dispatch(setWindowWidth(window.innerWidth)), 250)
      );

    }
    preLoad();

  }, [dispatch, location]);

  useEffect(() => {
    if (env === "dachnik") {
      let link = document.querySelector("link[rel~='icon']");
      link.href = dachnik_favicon;
      document.title = "Натуральные продукты от огородников онлайн  – dachnikrusagro.market";
    } else return

  }, [env])

  return (
    <div className="App">
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
        <LoadingBar />
        <Header />
        {addressModalVisible && <AddressModal />}
        {addressListModalVisible && <AddressListModal />}
        {isLogged && !isBuyer && <IsNotByuerModal visible={true}/>}
        <main>
          <Switch>
            <Route exact path="/" component={Home}></Route>
            <Route exact path="/landing" component={SuppliersLanding}></Route>
            <Route path="/catalog/product/:urlSegment" component={ProductPage} exact />
            <Route path="/catalog/suppliers/:supplierId" component={SupplierPage} />
            <Route path="/catalog/customerProducts/:category1?/:category2?/:category3?" component={ProductList} />
            {/* <Route path="/catalog/priceList" component={PriceList} /> */}
            {isLogged && <Route path="/profile/:section/:subsection?" component={ProfilePage} />}

            <Route path="/cart" component={CartPage} />
            {!global.config.isProd && <Route path="/fake-login" component={Fake} />}
            <Route path="/test" component={Test} />
            <Route path="/help" component={Help} exact />
            <Route path="/contacts" component={Contacts} exact />
            <Route path="/payment-success" component={PaymentSuccess} />
            <Route path="/app-links" component={ApplicationsLinksPage} />
            <Route path="/help/:categoryId/:questionsId" component={HelpQA} />
            <Route path="*" component={Home} />
          </Switch>
        </main>
        <Footer isMobile={isMobile} isTablet={isTablet} />
        <UserJoinRequestDialog />
        <CookiesAcceptModal />
        <AccountNotVerifiedModal />
        {isDesktop && <SideCart />}
      </ErrorBoundary>

      <YMInitializer
        accounts={[global.config.ymAccountId]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
          ecommerce: "dataLayer",
        }}
      />
    </div>
  );
};

export default App;
