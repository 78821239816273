import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import BackButton from "../../components/commons/BackButton/BackButton";
import Breadcrumbs from "../../components/commons/Breadcrumbs";
import "./HelpQA.scss";
import NavigationBlock from "../../components/Help/NavigationBlock";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { helpSetData } from "../../actions/help";
import { groupByCategory, assingHelpDataType } from "../../helpers/help";
import { helpJson } from "../../static/help";
import { helpDachnikJson } from "../../static/helpDachnik";

const breadcrumbItems = [
  {
    id: 0,
    text: "Главная",
    to: "/",
  },
  {
    id: 1,
    text: "Помощь",
    to: "/help",
  },
];

const { env } = global.config;
const isDacnhik = env === "dachnik";
const data = isDacnhik ? helpDachnikJson : helpJson;

const HelpQA = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const helpData = useSelector(({ help }) => help);
  const isMobile = useSelector((state) => state.api.isMobile);
  const qaItem = helpData?.data[Object.keys(helpData.data)[params.categoryId]];
  const qaItemQeustion = qaItem && qaItem[params.questionsId]?.question;
  const qaItemAnswer = qaItem && qaItem[params.questionsId]?.answer;
  const qaItemSection = qaItem && qaItem[params.questionsId]?.section;

  useEffect(() => {
    if (helpData.status === "success") {
      return;
    }

    dispatch(helpSetData(groupByCategory([...assingHelpDataType(data.dataBuyers, "buyers"), ...assingHelpDataType(data.dataSuppliers, "suppliers")])));
  }, [dispatch, helpData.status]);

  useEffect(() => {
    if (qaItemQeustion === "Какая зона доставки?") {
      const script = document.createElement("script");

      script.src = "/delivery-map.js";
      script.async = true;

      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [qaItemQeustion]);

  useEffect(() => window.scrollTo(0, 0));

  const handleBackButtonClick = (e) => {
    e.preventDefault();
    history.push("/help");
  };

  if (helpData.status === "success" && !qaItem) {
    history.push("/help");
    return null;
  }

  if (qaItem && !qaItem[params.questionsId]) {
    history.push("/help");
    return null;
  }

  return (
    helpData.status === "success" && (
      <>
        {isMobile ? (
          <div className="helpQARoot--mobile">
            <h1 className="helpQARoot__title-mobile">Помощь</h1>
            <div className="container">
              <BackButton label="Назад" onClick={handleBackButtonClick} />
              <div
                className={cn("helpQARoot--mobile__item-content", {
                  list: qaItem[0].link,
                })}
              >
                {!qaItemAnswer ? (
                  <>
                    <h2>{qaItemSection}</h2>
                    {qaItem.map(
                      (item, index) =>
                        item.link && (
                          <li key={index}>
                            <a href={item.link} target="_blank" rel="noopener noreferrer" className="helpQARoot__item-content-link">
                              {item.question}
                            </a>
                          </li>
                        )
                    )}
                    {qaItemAnswer}
                  </>
                ) : (
                  <>
                    <h2>{qaItemQeustion}</h2>
                    {qaItemAnswer}
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="helpQARoot">
            <div className="container">
              <Breadcrumbs
                items={
                  helpData.status === "success"
                    ? [
                        ...breadcrumbItems,
                        {
                          id: 2,
                          text: qaItemSection,
                          to: "",
                        },
                      ]
                    : breadcrumbItems
                }
              />
              <BackButton label="Назад" onClick={handleBackButtonClick} />
              <div className="helpQARoot__wrapper">
                <div className="helpQARoot__navigation">
                  {Object.keys(helpData.data).map((item, categoryIndex) => (
                    <NavigationBlock key={item} list={helpData.data[item]} categoryIndex={categoryIndex} />
                  ))}
                </div>
                <ul
                  className={cn("helpQARoot__item-content", {
                    list: !qaItemAnswer,
                  })}
                >
                  {!qaItemAnswer ? (
                    <>
                      <h2>{qaItemSection}</h2>
                      {qaItem.map(
                        (item, index) =>
                          item.link && (
                            <li key={index}>
                              <a href={item.link} target="_blank" rel="noopener noreferrer" className="helpQARoot__item-content-link">
                                {item.question}
                              </a>
                            </li>
                          )
                      )}
                      {qaItemAnswer}
                    </>
                  ) : (
                    <>
                      <h2>{qaItemQeustion}</h2>
                      {qaItemAnswer}
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
      </>
    )
  );
};

export default HelpQA;
