export const productsSortItems = {
  // popularity: { sortBy: "popularity", direction: "Desc", text: "По популярности" },
  priceDesc: { sortByFields: "price", directions: "Asc", text: "Сначала дешевые" },
  priceAsc: { sortByFields: "price", directions: "Desc", text: "Сначала дорогие" },
  createDate: { sortByFields: "changedDate", directions: "Desc", text: "Сначала новые" },
};

export const productFilters = [
  { name: "countriesOfOrigin", title: "Страна" },
  // { name: "features", title: "Особенности" },
  // { name: "transportPackingTypes", title: "Тип упаковки" },
];

export const productFilterNames = productFilters.map((i) => i.name);

export const defaultCartHintText = "Равным образом реализация намеченных плановых заданий требуют определения и уточнения новых предложений.";

export const validationMessages = {
  required: "Необходимо заполнить поле.",
};

export const TITLE_TAG_DELIVERY_ZONE_CAPTION = "в Новосибирске";

export const PICK_UP_ADDRESS = "Населённый пункт 3307 километр, улица 3307 км, 30кВ1";

export const PICK_UP_ADDRESS_DATA = {
  country: "Российская Федерация",
  locality: "Населённый пункт 3307 километр",
  number: "30кВ1",
  region: "",
  street: "улица 3307 км",
  entrance: "",
  building: "30кВ1",
};

const MAIN_PHONE = { nsk: "+79628404000", msk: "+74955453540", whatsapp: "+79628404000", dachnik: "+74952971546" };

export const CONTACTS = {
  TELEGRAM: "https://t.me/RusAgroMarket_bot",
  PHONE: MAIN_PHONE,
  PHONE_TEXT: { nsk: "+7 962 840-40-00", msk: "+7 (495) 545-35-40", dachnik: "8 499 393 34 07" },
  WHATSAPP: `https://api.whatsapp.com/send/?phone=${MAIN_PHONE.whatsapp}`,
  EMAIL_INFO: { market: "info@rosagromarket.ru", dachnik: "support@dachnikrusagro.market" },
  ADDRESS: { msk: "Москва, 1-й Магистральный туп., д. 5А БЦ «Магистраль Плаза»", dachnik: "115172, Россия, г. Москва, Краснохолмская наб., д. 11, стр. 1, помещ. 5/1" }
};

export const COMPANY_DETAILS = [
  {
    fullTitle: "Общество с ограниченной ответственностью «РАМ-Дистрибуция»",
    shortTitle: "ООО «РАМ-Дистрибуция»",
    address: "633102, Новосибирская обл., Новосибирский м.р-н, с.п. Толмачевский сельсовет, платф. 3307 км, д. 30, к. В1, помещ. 110",
    postalAddress: "633102, Новосибирская обл., Новосибирский м.р-н, с.п. Толмачевский сельсовет, платф. 3307 км, д. 30, к. В1, помещ. 110",
    inn: "5433977525",
    kpp: "543301001",
    ogrn: "1215400019845",
    okved: "46.31.1, 46.11.3, 46.17, 46.31.2, 46.32, 46.33, 46.34.1, 46.36, 46.37, 46.38.1, 46.38.23, 46.38.24, 46.38.26, 46.38.29, 46.39.1, 49.41, 52.10, 52.29, 53.20.3, 53.20.31",
    okpo: "61993222",
    bic: "044525999",
    bankTitle: "Точка ПАО Банка «ФК Открытие»",
    bankAddress: "109240, город Москва, улица Верхняя Радищевская, дом 2/1, строение 3",
    bankAccount: "40702810101500182436",
    corrAccount: "30101810845250000999",
    ceo: "Генеральный директор Харченко Михаил Михайлович",
    tel: CONTACTS.PHONE_TEXT.msk,
    email: "support@rusagro.market"
  },
  {
    fullTitle: "Общество с ограниченной ответственностью «РМ-ФИНАНС» RM-FINANCE LLC",
    shortTitle: "ООО «РМ-ФИНАНС»",
    address: "115172, Г.Москва, ВН.ТЕР.Г. МУНИЦИПАЛЬНЫЙ ОКРУГ ТАГАНСКИЙ, НАБ КРАСНОХОЛМСКАЯ, Д. 11, СТР. 1, ПОМЕЩ. 5/1",
    postalAddress: "115172, Г.Москва, ВН.ТЕР.Г. МУНИЦИПАЛЬНЫЙ ОКРУГ ТАГАНСКИЙ, НАБ КРАСНОХОЛМСКАЯ, Д. 11, СТР. 1, ПОМЕЩ. 5/1",
    inn: "9705166530",
    kpp: "770501001",
    ogrn: "1227700129447",
    okved: "66.19 — Деятельность вспомогательная прочая в сфере финансовых услуг, кроме страхования и пенсионного обеспечения",
    okpo: "70036152",
    bic: "044525974",
    bankTitle: "АО «ТИНЬКОФФ БАНК»",
    bankAddress: "127287, г. Москва, ул. 2-я Хуторская, д. 38А, стр. 26",
    bankAccount: "40702810210001024774",
    corrAccount: "30101810145250000974",
    ceo: "Генеральный директор Адамян Юлия Сергеевна",
    tel: CONTACTS.PHONE_TEXT.dachnik,
    email: CONTACTS.EMAIL_INFO.dachnik
  }
]

export const MAX_CART_ITEMS_WEIGHT = 17000;

export const REQUIRED_FIELDS = ["legalName", "legalAddress", "inn", "kpp", "bankAccount", "correspondentAccount", "bankFullName", "bic", "ceoFullName", "phoneNumber"];
export const REQUIRED_FIELDS_SELF_EMP = ["legalName", "legalAddress", "inn", "bankAccount", "correspondentAccount", "bankFullName", "bic", "ceoFullName", "phoneNumber"];

export const EMPTY_RESULTS = {
  itemsCount: 0,
  totalCount: 0,
  offset: 0,
  results: [],
};

export const MAP_LINK_DC = "https://2gis.ru/novosibirsk/directions/points/%7C82.541932%2C55.017486%3B70000001045915289?m=82.541924%2C55.017235%2F16"

export const MAIN_PRODUCT_CATEGORIES = [{ title: "Овощи, фрукты, ягоды", id: "2a4ac7ab-2e04-4bb2-85b0-4de7e93ed6ea" },
{ title: "Мясная продукция", id: "5788a044-0f37-4323-bdd6-cfb29ac89101" },
{ title: "Бакалея", id: "e130d9d2-65a1-469a-8577-8be5e06518d7" },
{ title: "Замороженные продукты", id: "53d947a6-dac9-471b-ab3f-2bd751b82b56" }
];

export const DELIVERY_TYPES = [
  "DoorDelivery",
  "Pickup"
]

//temporary discounted products 

export const PROMOTED_PRODUCTS = [
  "1cd49c5d-55e8-4241-a665-6d4e471e2bc4",
  "0203b62b-bf08-4c3a-92e5-3e429c7609bc",
  "92f2b93f-dec6-4a6e-a1d7-6f743712d52c",
  "69919221-a42b-43c9-be0b-3ecc2099c49d",
  "b443dc19-5c07-4fe1-9a44-77e6aa2fd181",
  "fcbdd4ec-0fb1-4d35-9227-7e3fef125928",
  "ff9eeb9d-a14c-4b8e-bf2e-95d6594b19d2",
  "458d7c10-72e4-4d5e-840c-cdf6ccf917f2",
  "7e83affe-a548-4f09-ade0-6b071dbc66a9",
  "e941113d-272f-4836-9e24-0c2067981d09",
  "e941113d-272f-4836-9e24-0c2067981d09",
  "bc5557a3-2ae2-442f-ac7a-e559aebf435d",
  "3fe873d8-0c0e-4e59-86cb-b3ff0f757626",
  "e49b2fa6-2484-4fc1-9fc5-5a1d820ca5ce",
  "1d15cd87-ce99-4dcf-afce-ab375a61b4c8",
  "8cc6bacc-031a-4406-a73d-c256af854668",
  "a968ebe3-c7f0-48b3-bb7a-ab84f684c122",
  "4f13733d-d814-42a2-b899-18b9017946cf",
  "991833ca-9167-41c2-bcb8-6312e347fb8d",
  "210225e1-61bd-4423-a3eb-b685c302bd1b",
  "0f5d20c5-6e31-41c7-8d5e-bea9e353c349",
  "4f53de57-0e24-4e2d-b4ed-b11c60c587aa",
  "386cc9ca-dbd9-41bc-8a2a-21bb5ef34355",
  "40d8d0ff-d2e9-42a3-b629-e738ba1e1f81",
  "52ec53d1-c1eb-4377-94c2-bbdfadbe2f1f",
  "a50dd553-049f-4e86-8f71-29f5ec2aeb59",
  "61f44a4a-fe4b-4880-ad69-4f95f1dde294",
  "fb794be2-3258-470e-ba6e-a02dd12649de",
  "257e9b84-fdbc-435f-8604-15e630cd468f",
  "2f086bce-13c5-4e66-b09f-c51fc40170c6",
  "144c567e-6804-4211-a0c3-bf0e5ddf1356",

  "d562cb45-852e-4597-8791-e495c5c3694b"  //delete after test 
]