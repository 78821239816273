import React from 'react';
import { useHistory } from "react-router-dom";
import Button from '../../commons/Button/Button';
import Card from '../../Product/Card/Card';
import CardSkeleton from "../../Product/Card/Skeleton/Card";
import "./PopularProducts.scss";

const PopularProducts = ({ products, loaded, isMobile, isTablet }) => {

  const history = useHistory();


  const renderCards = () => {

    if (!loaded) {
      return [1, 2, 3, 4].map((i) => (
        <li key={i}>  <CardSkeleton isMobile={isMobile} isTablet={isTablet} /> </li>
      ))
    } else return products.map((product) => (
      <li key={product.id}>
        <Card product={product} isMobile={isMobile} isTablet={isTablet} />
      </li>
    ))

  }

  const handleRedirect = () => {
    history.push("/catalog/customerProducts");
  }

  return (
    <div className='popular-products'>
      <header className='popular-products-header'>
        <h2 className='popular-products-title'>Популярное</h2>
        <div className='popular-products-button'>
          <Button title="Показать ещё" buttonSize="small" buttonType="subtle" width="100%" onClick={handleRedirect} />
        </div>

      </header>

      <ul className='popular-products-list'>
        {renderCards()}
      </ul>
    </div>
  )
}

export default PopularProducts