import React from "react";
import FaqItem from "./FaqItem";
import { helpJson } from "../../../../static/help";
import { helpDachnikJson } from "../../../../static/helpDachnik";
import "./Faq.scss";
const { env } = global.config;
const isDacnhik = env === "dachnik";
const data = isDacnhik ? helpDachnikJson : helpJson;

const Faq = () => {
  return (
    <ul className="faq-list">
      <FaqItem title={data.dataBuyers[5].question} content={data.dataBuyers[5].answer} />
      <FaqItem title={data.dataBuyers[8].question} content={data.dataBuyers[8].answer} />
      <FaqItem title={data.dataBuyers[10].question} content={data.dataBuyers[10].answer} />
      <FaqItem title={data.dataBuyers[11].question} content={data.dataBuyers[11].answer} />
      {!isDacnhik && <FaqItem title="Как стать поставщиком ?" content={data.dataSuppliers[0].answer} />}
    </ul>
  );
};

export default Faq;
